@import url("https://fonts.googleapis.com/css2?family=Genos:wght@600&family=Lato:wght@300;400;700&family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  /*  Commented to allow background image to have unlimited vertical scrolling  */
  /*height: 100%; */
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.chat {
  height: 100%;
  width: 100%;
  font-family: "Lato", sans-serif;
  font-size: 15px;
}
.chatUserMessage {
  height: 100%;
  width: 100%;
  font-family: "Lato", sans-serif;
  font-size: 16px;
}
.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #555;
}
